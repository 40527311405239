var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c(
            "div",
            { staticClass: "card", staticStyle: { "min-height": "500px" } },
            [
              _c("div", { staticClass: "card-body" }, [
                _c("h5", { staticClass: "card-title mb-4" }, [
                  _vm._v("Impression")
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "sr-only",
                          attrs: { for: "inlineFormInputName2" }
                        },
                        [_vm._v("Name")]
                      ),
                      _c("VueCtkDateTimePicker", {
                        staticStyle: {
                          display: "inline-block",
                          "margin-bottom": "20px"
                        },
                        attrs: {
                          locale: "fr",
                          range: true,
                          color: "#34495e",
                          "no-label": true,
                          "custom-shortcuts": _vm.customDateRangeShortcuts,
                          "only-date": true,
                          "auto-close": false
                        },
                        model: {
                          value: _vm.odsFilterForm.dateRange,
                          callback: function($$v) {
                            _vm.$set(_vm.odsFilterForm, "dateRange", $$v)
                          },
                          expression: "odsFilterForm.dateRange"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary" },
                          on: { click: _vm.getFilteredOdsList }
                        },
                        [
                          _c("i", { staticClass: "fas fa-filter label-icon" }),
                          _vm._v(" Filtrer")
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "ml-4",
                          attrs: { variant: "warning" },
                          on: { click: _vm.resetFilterForm }
                        },
                        [
                          _c("i", {
                            staticClass: "far fa-hourglass label-icon"
                          }),
                          _vm._v(" Réinitialiser")
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row d-flex justify-content-end" }, [
                  _vm.showPrintRecapBtn
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-6 mb-4 d-flex justify-content-end align-items-center"
                        },
                        [
                          _c("multiselect", {
                            staticClass: "here",
                            attrs: {
                              "deselect-label": "",
                              "select-label": "",
                              searchable: true,
                              "track-by": "id",
                              label: "ref",
                              options: _vm.contracts,
                              placeholder: "Selectionner un contrat",
                              "allow-empty": false,
                              multiple: false
                            },
                            on: { select: _vm.getContractID },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "singleContract",
                                  fn: function(ref) {
                                    var contract = ref.contract
                                    return [_vm._v(_vm._s(contract.ref))]
                                  }
                                }
                              ],
                              null,
                              false,
                              1263483053
                            ),
                            model: {
                              value: _vm.selectedCTT,
                              callback: function($$v) {
                                _vm.selectedCTT = $$v
                              },
                              expression: "selectedCTT"
                            }
                          }),
                          _c("multiselect", {
                            attrs: {
                              "select-label": "",
                              "track-by": "id",
                              label: "name",
                              placeholder: "Sélectionner la base de vie",
                              options: _vm.camps,
                              searchable: true,
                              "allow-empty": false
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "singleLabel",
                                  fn: function(ref) {
                                    var option = ref.option
                                    return [
                                      _c("strong", [
                                        _vm._v(_vm._s(option.name))
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2784876651
                            ),
                            model: {
                              value: _vm.odsFilterForm.camp,
                              callback: function($$v) {
                                _vm.$set(_vm.odsFilterForm, "camp", $$v)
                              },
                              expression: "odsFilterForm.camp"
                            }
                          }),
                          _c(
                            "b-button",
                            {
                              staticClass: "ml-2",
                              attrs: { variant: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.printRecap("absences_recap")
                                }
                              }
                            },
                            [_vm._v("Imprimer Récap ")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c("dataset", {
                        attrs: { "ds-data": _vm.odsList },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var ds = ref.ds
                              return [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-md-12" }, [
                                    _c("div", {}, [
                                      _c(
                                        "table",
                                        {
                                          staticClass:
                                            "table table-hover d-md-table"
                                        },
                                        [
                                          _c("thead", [
                                            _c(
                                              "tr",
                                              _vm._l(_vm.cols, function(th) {
                                                return _c(
                                                  "th",
                                                  { key: th.field },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(th.name) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              }),
                                              0
                                            )
                                          ]),
                                          _c("dataset-item", {
                                            attrs: { tag: "tbody" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    var rowIndex = ref.rowIndex
                                                    return [
                                                      _c("tr", [
                                                        _c("th", [
                                                          _vm._v(
                                                            _vm._s(rowIndex + 1)
                                                          )
                                                        ]),
                                                        _c("th", [
                                                          _vm._v(
                                                            _vm._s(row.ref)
                                                          )
                                                        ]),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              row.lifebase.name
                                                            )
                                                          )
                                                        ]),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              row.contract.ref
                                                            )
                                                          )
                                                        ]),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              row
                                                                .service_provider
                                                                .prestname
                                                            )
                                                          )
                                                        ]),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              row.date_ODS_format
                                                            )
                                                          )
                                                        ]),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              row.date_exec_ODS_format
                                                            )
                                                          )
                                                        ]),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              row.totalAmnt
                                                                .withCurrency
                                                            )
                                                          )
                                                        ]),
                                                        _c("td", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              row.status_label
                                                            )
                                                          }
                                                        }),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-center"
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "b-tooltip",
                                                                    rawName:
                                                                      "v-b-tooltip.hover.top",
                                                                    value:
                                                                      "Imprimé ODS Validé",
                                                                    expression:
                                                                      "'Imprimé ODS Validé'",
                                                                    modifiers: {
                                                                      hover: true,
                                                                      top: true
                                                                    }
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  href:
                                                                    "javascript:void(0)"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.printSingleDocument(
                                                                      "single_gs_so",
                                                                      row.uuid
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fas fa-print"
                                                                })
                                                              ]
                                                            ),
                                                            row.verified == 1 &&
                                                            _vm.verifyAbsences(
                                                              row
                                                            )
                                                              ? _c(
                                                                  "a",
                                                                  {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "b-tooltip",
                                                                        rawName:
                                                                          "v-b-tooltip.hover.top",
                                                                        value:
                                                                          "Imprimé Etat d'Absences",
                                                                        expression:
                                                                          "'Imprimé Etat d\\'Absences'",
                                                                        modifiers: {
                                                                          hover: true,
                                                                          top: true
                                                                        }
                                                                      }
                                                                    ],
                                                                    staticClass:
                                                                      "ml-3",
                                                                    attrs: {
                                                                      href:
                                                                        "javascript:void(0)"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.printSingleDocument(
                                                                          "single_gs_abs",
                                                                          row.uuid
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fas fa-print text-warning"
                                                                    })
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      ])
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  ])
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-md-row flex-column justify-content-between align-items-center"
                                  },
                                  [
                                    _c("dataset-show", {
                                      attrs: { "ds-show-entries": 5 }
                                    }),
                                    _c("dataset-pager")
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ])
              ])
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }