<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Dataset from "vue-dataset/dist/es/Dataset.js";
import DatasetItem from "vue-dataset/dist/es/DatasetItem.js";
import DatasetPager from "vue-dataset/dist/es/DatasetPager.js";
import DatasetShow from "vue-dataset/dist/es/DatasetShow.js";
import Multiselect from 'vue-multiselect';

import {printSingleDocumentUrl, printRecapDocumentUrl} from "@/helpers/functions";
/**
 * Starter component
 */
export default {
  page: {
    title: "Editions",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    Dataset,
    DatasetItem,
    DatasetPager,
    DatasetShow,
    Multiselect
  },
  import: {printSingleDocumentUrl, printRecapDocumentUrl},
  data() {
    return {
      title: "Editions Espaces Verts",
      // items: [],
      camps: [],
      odsList: [],
      showPrintRecapBtn: false,
      selectedCTT:[],
      selectedBdv:[],
      contracts:[],
      bases:[],
      supplier:[],
      items: [
        {
          text: "Catalogue des Services",
          to: {name:"service_catalog"},
        },
        {
          text: "Espaces Verts",
          to: {name:"service_catalog.green_areas"},
        },
        {
          text: "Editions",
          active: true,
        },
      ],
      customDateRangeShortcuts: [
        { key: "thisWeek", label: "Cette semaine", value: "isoWeek" },
        { key: "lastWeek", label: "Semaine dernière", value: "-isoWeek" },
        { key: "last7Days", label: "7 derniers jours", value: 7 },
        { key: "last30Days", label: "30 derniers jours", value: 30 },
        { key: "thisMonth", label: "Ce mois", value: "month" },
        { key: "lastMonth", label: "Dernier mois", value: "-month" },
        { key: "thisYear", label: "Cette année", value: "year" },
        { key: "lastYear", label: "L'année dernière", value: "-year" },
      ],
      odsFilterForm: {
        dateRange: "",
        camp: null,
      },
      cols: [
        {
          name: "#",
          field: "id",
          sort: "",
        },
        {
          name: "N° ODS",
          field: "ref",
          sort: "",
        },
        {
          name: "Base de vie",
          field: "lifebase",
          sort: "",
        },
        {
          name: "Contrat",
          field: "contract",
          sort: "",
        },
        {
          name: "Prestataire",
          field: "supplier",
          sort: "",
        },
        {
          name: "Date ODS",
          field: "date_ods",
          sort: "",
        },
        {
          name: "Date Execution",
          field: "date_exec_ods",
          sort: "",
        },
        {
          name: "Coût ODS",
          field: "total_amount",
          sort: "",
        },
        {
          name: "Statut",
          field: "status",
          sort: "",
        },
        {
          name: "Imprimer",
          field: "print",
          sort: "",
        },
      ],
    };
  },
  mounted(){
    this.getlistContracts();
  },
  methods: {
    printSingleDocument(doc, uid){
      printSingleDocumentUrl(doc, uid);
    },
    printRecap(doc){
      if(this.odsFilterForm.camp == null){
        this.$toast.error("Veuillez sélectionner une base de vie.");
        
      }else{
          var data = {
          campId: this.odsFilterForm.camp.uuid,
          supplier: this.supplier.uuid,
          contract: this.selectedCTT.uuid,
          from: this.odsFilterForm.dateRange.start,
          to: this.odsFilterForm.dateRange.end,
        }
        printRecapDocumentUrl(doc, data);
      }
    },

    _checkDateRange(){
        var checked = true;
        var from = this.odsFilterForm.dateRange.start;
        var to = this.odsFilterForm.dateRange.end;
        if((from == "" && to == "") || (from == undefined && to == undefined)){
            checked = false;
            this.$toast.error("Veuillez sélectionner une plage de dates.");
        }
        return checked;
    },
    resetFilterForm(){
        this.odsList = [];
        this.odsFilterForm.dateRange = "";
        this.odsFilterForm.camp = null;
        this.showPrintRecapBtn = false;
    },
    getlistContracts(){
      this.$http.post('/ods/listContractsBytype',{type:"green_space"})
      .then(response => this.contracts = response.data)
      .catch(error=> console.log(error))
    },
    getContractID(contract){
        this.selectedContract = contract;
        this.supplier         = contract.supplier;
        this.bases            = contract.camps_services.map(element => {return element.camp});
    },
    getFilteredOdsList() {
      var checkDateRange = this._checkDateRange();
      if (checkDateRange) {
        var loader = this.$loading.show();
        this.$http
          .post("/ods/getFilteredOds", {
            type: "green_space",
            from: this.odsFilterForm.dateRange.start,
            to: this.odsFilterForm.dateRange.end,
          })
          .then((res) => {
            loader.hide();
            var odsList = res.data.odsList;
            this.odsList = odsList;
            this.camps = res.data.filteredCampsList;
            this.supplier = res.data.supplier;
            this.showPrintRecapBtn = odsList.length > 1;
          })
          .catch((error) => {
            loader.hide();
            console.log(error);
          });
      }
    },
    verifyAbsences(ods){
      var total =0;
      var keys = Object.keys(ods.items);
      keys.forEach(x => {
        if(x == 'Entretien'){
          let indexs = Object.keys(ods.items[x])
          indexs.forEach(y => {
            if(y == 'human'){
              ods.items[x][y].forEach(element => {
                total += element.absences.length
              })
            }
          })
        }
      })

      return total > 0;
    }
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 500px;">
          <div class="card-body">
            <h5 class="card-title mb-4">Impression</h5>

            <div class="row">
              <div class="col-md-3">
                <label class="sr-only" for="inlineFormInputName2">Name</label>
                <VueCtkDateTimePicker
                  locale="fr"
                  v-model="odsFilterForm.dateRange"
                  style="display:inline-block;margin-bottom: 20px;"
                  :range="true"
                  color="#34495e"
                  :no-label="true"
                  :custom-shortcuts="customDateRangeShortcuts"
                  :only-date="true"
                  :auto-close="false"
                ></VueCtkDateTimePicker>
              </div>
              <!-- <div class="col-md-3">
                <multiselect v-model="selectedCTT" :deselect-label="''" class="here" @select="getContractID" :select-label="''" :searchable="true" track-by="id" label="ref" :options="contracts" placeholder="Selectionner un contrat" :allow-empty="false" :multiple="false">
                  <template slot="singleContract" slot-scope="{ contract }">{{ contract.ref }}</template>
                </multiselect>
              </div>
              <div class="col-md-3">
                <multiselect v-model="selectedBdv" :deselect-label="''" class="here" :select-label="''" placeholder="Selectionner une base de vie" :searchable="true" track-by="id" label="name" :options="bases" :allow-empty="false" :multiple="false">
                  <template slot="singleOds" slot-scope="{ base }">{{ base.name }}</template>
                </multiselect>
              </div> -->
              <div class="col-md-3">
                <b-button variant="primary" @click="getFilteredOdsList"
                  > <i class="fas fa-filter label-icon"></i> Filtrer</b-button
                >
                <b-button variant="warning" class="ml-4" @click="resetFilterForm"
                  > <i class="far fa-hourglass label-icon"></i> Réinitialiser</b-button
                >
              </div>
            </div>

            <div class="row d-flex justify-content-end">
              <div class="col-6 mb-4 d-flex justify-content-end align-items-center" v-if="showPrintRecapBtn">
                <!-- <div > -->
                    <!-- <a href="javascript:void(0)"  @click="odsFilterForm.camp = null">Réinitialiser</a> -->
                    <multiselect v-model="selectedCTT" :deselect-label="''" class="here" @select="getContractID" :select-label="''" :searchable="true" track-by="id" label="ref" :options="contracts" placeholder="Selectionner un contrat" :allow-empty="false" :multiple="false">
                      <template slot="singleContract" slot-scope="{ contract }">{{ contract.ref }}</template>
                    </multiselect>
                    <multiselect v-model="odsFilterForm.camp" :select-label="''" track-by="id" label="name" placeholder="Sélectionner la base de vie" :options="camps" :searchable="true" :allow-empty="false">
                      <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                    </multiselect>
                  
                  <b-button @click="printRecap('absences_recap')" class="ml-2" variant="primary">
                    <!-- <i class="fas fa-print label-icon"></i> -->Imprimer Récap 
                  </b-button>
                <!-- </div> -->
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <dataset v-slot="{ ds }" :ds-data="odsList">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="">
                        <table class="table table-hover d-md-table">
                          <thead>
                            <tr>
                              <th v-for="th in cols" :key="th.field">
                                {{ th.name }}
                              </th>
                            </tr>
                          </thead>
                          <dataset-item tag="tbody">
                            <template #default="{ row, rowIndex }">
                              <tr>
                                <th>{{ rowIndex + 1 }}</th>
                                <th>{{ row.ref }}</th>
                                <td>{{ row.lifebase.name }}</td>
                                <td>{{ row.contract.ref }}</td>
                                <td>{{ row.service_provider.prestname }}</td>
                                <td>{{ row.date_ODS_format }}</td>
                                <td>{{ row.date_exec_ODS_format }}</td>
                                <td>{{ row.totalAmnt.withCurrency }}</td>
                                <td v-html="row.status_label"></td>
                                <td class="text-center">
                                  <a v-b-tooltip.hover.top="'Imprimé ODS Validé'" @click="printSingleDocument('single_gs_so', row.uuid)" href="javascript:void(0)">
                                    <i class="fas fa-print"></i>
                                  </a>

                                  <a v-if="row.verified==1 &&  verifyAbsences(row)" v-b-tooltip.hover.top="'Imprimé Etat d\'Absences'" @click="printSingleDocument('single_gs_abs', row.uuid)" class="ml-3" href="javascript:void(0)">
                                    <i class="fas fa-print text-warning"></i>
                                  </a>
                                </td>
                              </tr>
                            </template>
                          </dataset-item>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex flex-md-row flex-column justify-content-between align-items-center"
                  >
                    <dataset-show :ds-show-entries="5" />
                    <dataset-pager />
                  </div>
                </dataset>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
